import React from 'react'
import AddAccountForm from '../components/AddAccountForm'
import { Container, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'


import {resetCreateAccount, resetCreateAccountError} from '../features/addAccountSlice'
import { getAdminUsers } from '../features/getAdminUsersSlice';
import { clearGetAdminUsersError } from '../features/getAdminUsersSlice';


function AddAwsAccountPage() {
    const { account, loading, error, errorMessage, isSuccess } = useSelector((state) => state.create_account)
    const admin_users = useSelector((state) => state.admin_users)
    const dispatch = useDispatch()

    function handleOnCloseError(){
        dispatch(resetCreateAccountError())
        dispatch(clearGetAdminUsersError())
    }

    function handleOnClose(){
        dispatch(resetCreateAccount())
    }

    const { instance, inProgress, accounts } = useMsal();

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAdminUsers(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch]);

  return (
    <Container>
        <p className="lead">Add AWS Account</p>
        <hr></hr>
        { loading && <Spinner variant="primary"/>}
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert>}
        { admin_users.error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{admin_users.errorMessage}</Alert>}
        { isSuccess && <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnClose}>#{account.account_number} {account.message}</Alert>}
        <AddAccountForm />
    </Container>
  )
}

export default AddAwsAccountPage