import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    requests: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getExpireRequests = createAsyncThunk(
     'getExpireRequests',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'req_type': data.type
      }
    
    try {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/request`, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const getExpireRequestsSlice = createSlice({
    name: 'getActiveRequests',
    initialState,
    error: null,
    reducers:{
        clearGetExpireRequestError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: {
        [getExpireRequests.pending] : (state) => {
            state.loading = true
        },
        [getExpireRequests.fulfilled] : (state, action) => {
            state.loading = false
            state.requests = action.payload
            state.isLoaded = true
            state.error = false
        },
        [getExpireRequests.rejected] : (state, action) => {
            state.loading = false
            state.isLoaded = true
            state.error = true
            state.errorMessage =  action.payload.message
        },
    }
})

export const {clearGetExpireRequestError} = getExpireRequestsSlice.actions

export default getExpireRequestsSlice.reducer