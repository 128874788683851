import React from 'react'
import { Button, Modal, Row, Col, Card, Form } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';

import { deleteUser } from '../features/deleteUserSlice';
import { updateUser } from '../features/updateUserSlice';

function UserManagement(props) {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();

    const [deleteShow, setDeleteShow] = useState(false)
    const [updateShow, setUpdateShow] = useState(false)
    const handleUpdateClose = () => setUpdateShow(false)
    const handleUpdateShow = () => {
        setUpdateShow(true)
    }

    const handleDeleteShow = () => {
        setDeleteShow(true)
    }
    const handleDeleteClose = () => setDeleteShow(false)
    const [formData, setFormData] = useState(
        {
            role: props.data.role,
        }
      )
    
    function handleChange(event) {
        console.log(formData)
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function deleteUserHandler(){
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'user_email': props.data.email,
                'user_role': props.data.role
            }
            dispatch(deleteUser(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });

            setDeleteShow(false)
        }
    }

    function updateUserHandler(){
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'user_email': props.data.email,
                'user_role': formData.role
            }
            dispatch(updateUser(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });

            setUpdateShow(false)
        }
    }

  return (
    <>
        <Button variant="primary" onClick={handleUpdateShow}>Update</Button>
            <Modal show={updateShow} onHide={handleUpdateClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update user
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={4}>
                        <Card.Text><strong>User Email:</strong> </Card.Text>
                    </Col>
                    <Col>
                        <Card.Text> {props.data.email} </Card.Text>
                    </Col>
                </Row>

                <Row>
                    <Col sm={4}>
                        <Card.Text><strong>Role:</strong> </Card.Text>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Select 
                                aria-label="Default select example"
                                id="role"
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                                required
                                //defaultValue={props.data.role}
                            >
                            <option value='user'>User</option>
                            <option value='admin'>Admin</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Select an a Role which user needs access to
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleUpdateClose} className='mx-3'>
                Cancel
            </Button>
            <Button variant="danger" onClick={updateUserHandler} type="submit">
                Update
            </Button>
            </Modal.Footer>
        </Modal>
        <Button variant="danger" className='mx-3' onClick={handleDeleteShow}>Remove</Button>
        <Modal show={deleteShow} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete User?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={4}>
                        <Card.Text><strong>User Email:</strong> </Card.Text>
                    </Col>
                    <Col>
                        <Card.Text> {props.data.email} </Card.Text>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Card.Text><strong>User Role:</strong> </Card.Text>
                    </Col>
                    <Col>
                        <Card.Text> {props.data.role} </Card.Text>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleDeleteClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteUserHandler}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default UserManagement