import React from 'react'
import { Container } from 'react-bootstrap'


function ErrorPage() {
  return (
    <Container>
            <p className="lead">404! Page Not Found
            </p>
    </Container>
  )
}

export default ErrorPage