import React from 'react'
import { Col, Row, Container, Alert } from 'react-bootstrap'
import { BrowserRouter } from "react-router-dom";
import { AuthenticatedTemplate  } from '@azure/msal-react'
import { useSelector } from 'react-redux'

import SideBar from '../components/Sidebar'
import AppRoutes from '../AppRoutes'
import Footer from "../components/Footer";
import Header from "../components/Header";

function Template() {

    const { error, errorMessage} = useSelector((state) => state.user)

  return (
    <BrowserRouter>
        <Header />
        <br></br><br></br><br></br><br></br>
        <main className='py-3 my-2'>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <AuthenticatedTemplate>
                            { !error &&
                                <SideBar />
                            }
                            </AuthenticatedTemplate>
                        </Col>
                        <Col>
                            { error && <Alert variant='danger' dismissible className='ms-auto'>{errorMessage}</Alert> }
                            <AppRoutes />
                            
                        </Col>
                    </Row>
                </Container>
        </main>
        <Footer />
    </BrowserRouter>
  )
}

export default Template