import React from 'react'

import { LinkContainer } from 'react-router-bootstrap'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { 
    Image, 
    Navbar, 
    Nav, 
    Container, 
    NavDropdown } from 'react-bootstrap'

import { useSelector } from 'react-redux';

function Header() {
    const { loginUser, isLoaded, error} = useSelector((state) => state.user)
    const { instance } = useMsal();

    function handleSignOut() {
            instance.logoutRedirect();
        }


  return (
    <Navbar expand="lg" className="bg-primary fixed-top" data-bs-theme="dark">
        <Container className="container-fluid">
            <LinkContainer to="/">
                <Navbar.Brand style={{padding:'10px'}}>
                    <Image src='https://aws-admin.mosaic.siemens-energy.cloud/images/logo.svg' style={{height:'40px', marginRight:'20px', 'borderRadius':'5%' }} alt='SE' fluid/>
                    Mosaic AWS Access Portal
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <AuthenticatedTemplate>
                    <Nav className="ms-auto">
                        <NavDropdown title={ (isLoaded && !error) ? loginUser.user_name + ' ( ' + loginUser.role.toUpperCase() + ' )' : ''} id="basic-nav-dropdown">
                            {loginUser.role === 'admin' && 
                                <LinkContainer to="/new/user">
                                    <NavDropdown.Item>
                                        Add User
                                    </NavDropdown.Item>
                                </LinkContainer>
                            }
                            {loginUser.role === 'admin' && 
                                <LinkContainer to="/edit/user">
                                    <NavDropdown.Item>
                                        Edit/Remove User
                                    </NavDropdown.Item>
                                </LinkContainer>
                            }
                            {loginUser.role === 'admin' && 
                                <LinkContainer to="/new/account">
                                    <NavDropdown.Item>
                                        Add AWS Account
                                    </NavDropdown.Item>
                                </LinkContainer>
                            }
                            <LinkContainer to="/profile">
                                <NavDropdown.Item>
                                        Profile
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={handleSignOut}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Nav className="ms-auto">
                        <LinkContainer to="/login">
                            <Nav.Link className="d-flex">
                                Login
                            </Nav.Link>
                        </LinkContainer>
                    </Nav>
                </UnauthenticatedTemplate>
            </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Header