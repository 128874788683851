import React from 'react'
import { Container, Table, Spinner, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { getAllUsers, clearGetAllUsersError } from '../features/getAllUsersSlice';
import UserManagement from '../components/UserManagement';
import { clearDeleteUsers, clearDeleteUsersError } from '../features/deleteUserSlice';
import { resetUpdateUser, resetUpdateUserError } from '../features/updateUserSlice';

function EditUserPage() {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();
    const { users, loading, error, errorMessage } = useSelector((state) => state.all_users)
    const delete_user = useSelector((state) => state.delete_user)
    const update_user = useSelector((state) => state.update_user)

    function handleOnCloseError(){
        dispatch(clearGetAllUsersError())
        dispatch(clearDeleteUsersError())
        dispatch(resetUpdateUserError())
        
    }

    function handleOnClose(){
        dispatch(clearDeleteUsers())
        dispatch(resetUpdateUser())
    }

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAllUsers(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch, delete_user.isSuccess, update_user.isSuccess]);


    const all_users = users.map( (user, index) => {
        return (<tr key={user.email} className={index%2 === 0 ? 'table-default' : 'table-light'}>
                    <th>{++index}</th>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                        <UserManagement data={user}/>
                    </td>
                </tr>)
    })

  return (
    <Container>
        <p className="lead">User Mangement</p>
        <hr></hr>
        { loading && <Spinner /> }
        { delete_user.loading && <Spinner /> }
        { update_user.loading && <Spinner /> }
        { delete_user.isSuccess && <Alert variant='primary' dismissible onClose={handleOnClose}>{delete_user.successMessage}</Alert> }
        { update_user.isSuccess && <Alert variant='primary' dismissible onClose={handleOnClose}>{update_user.successMessage}</Alert> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert> }
        
        <Table className='table table-hover'>
            <thead>
                <tr className='table-light'>
                <th>#S No.</th>
                <th>User Email</th>
                <th>Role</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
                { all_users }
            </tbody>

        </Table>
    </Container>
  )
}

export default EditUserPage