import React from 'react'
import { Accordion, Badge, Button, Row, Col, Container, Modal } from 'react-bootstrap'
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";

import { cancelRequest } from '../features/cancelRequestSlice';
import GetCredentials from './GetCredentials';


function ActiveRequestCard(props) {

    const { instance, inProgress, accounts } = useMsal()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const { loading} = useSelector((state) => state.cancel_request)

    function handleCancelRequest(e){
        const id = e.target.value
        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
        };

        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                const data = {
                    'access_token': `Bearer ${accessToken}`,
                    'email': instance.getActiveAccount().username,
                    'id': id
                }
                dispatch(cancelRequest(data))
                })
                .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
            });
        }
        setShow(false)
    }
    

  return (
    <>
        <Accordion.Item eventKey={props.data.id} className='border-dark'>
            <Accordion.Header>
                <strong>#{props.data.id} Admin Access Request :- </strong> {props.data.account_tag}/{props.data.account_number}
                {
                    props.data.status === 'pending' ?
                        <Badge bg="warning" className='mx-auto'>Pending Approval</Badge> 
                        : 
                        <Badge bg="success" className='mx-auto'>Approved</Badge> 
                }
            </Accordion.Header>
            <Accordion.Body>
                    <Row>
                        <Col sm={4}>
                        <strong>Request Id:</strong>
                        </Col>
                        <Col>
                            #{props.data.id}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Account Name:</strong>
                        </Col>
                        <Col>
                            {props.data.account_tag}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Account Number:</strong>
                        </Col>
                        <Col>
                            {props.data.account_number}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Request Date/Time (UTC):</strong>
                        </Col>
                        <Col>
                            {props.data.create_time_stamp}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Approved By:</strong>
                        </Col>
                        <Col>
                        {
                            props.data.status === 'approved' && props.data.decision_maker_email
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        <strong>Expiry Date/Time (UTC):</strong>
                        </Col>
                        <Col>
                        {
                            props.data.status === 'approved' && props.data.expiry_time_stamp
                        }
                        </Col>
                    </Row>
                    { props.data.status === 'pending' ?
                    <>
                        <Button variant="danger" className='mt-3' onClick={handleShow} disabled={loading}>Cancel Request</Button> 
                        <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you Sure you want to cancel the below access request?</Modal.Body>
                        <Modal.Body>
                            <Row>
                                <Col sm={4}>
                                <strong>Account Name:</strong>
                                </Col>
                                <Col>
                                    {props.data.account_tag}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                <strong>Account Number:</strong>
                                </Col>
                                <Col>
                                    {props.data.account_number}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="danger" onClick={handleCancelRequest} value={props.data.id}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            No
                        </Button>
                        </Modal.Footer>
                        </Modal>

                    </>
                    : 
                    <Container>
                        <GetCredentials data={props.data}/>
                    </Container>
                    }
                </Accordion.Body>
        </Accordion.Item>
    </>
  )
}

export default ActiveRequestCard