import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    loading: false,
    isSucess: false,
    error: false,
    create_request_response: [],
    errorMessage: null
}

export const createRequest = createAsyncThunk(
    'createRequest',
     async (data, {rejectWithValue}) => {

    const headers = {
    'access_token': data.access_token,
    }
    const body = {
        'account_number': data.account_number, 
        'email': data.email, 
        'account_tag': data.account_tag, 
        'days': data.days, 
        'reason': data.reason
    }
                               
   try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/request`, body, { headers })
   
       if (response.status === 200) {
           return response.data
       }

       } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
        return rejectWithValue(error.response.data)
   }
 })

 export const createRequestSlice = createSlice({
    name: 'accounts',
    initialState,
    error: null,

    reducers:{
        resetCreateRequest: (state) => {
            state.isSucess = false
        },
        resetCreateRequestError: (state) => {
            state.error = false
            state.errorMessage = null
        },
    },

    extraReducers: {
        [createRequest.pending] : (state) => {
            state.loading = true
        },
        [createRequest.fulfilled] : (state, action) => {
            state.loading = false
            state.create_request_response = action.payload
            state.isSucess = true
        },
        [createRequest.rejected] : (state, action) => {
            state.loading = false
            state.isSucess = false
            state.error = true
            console.log(action.payload)
            state.errorMessage = action.payload
        },
    }
})

export const {resetCreateRequest, resetCreateRequestError} = createRequestSlice.actions

export default createRequestSlice.reducer