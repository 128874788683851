import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    awsAccounts: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getAccountsData = createAsyncThunk(
    'accountDetails',
     async (data, {rejectWithValue}) => {

     const headers = {
       'access_token': data.access_token,
       'email': data.email
     }
   
   try {

       const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/accounts`, { headers })
       if (response.status === 200) {
           return response.data
        }
       } catch (error){
            console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
       }
 })

 export const getAccountsSlice = createSlice({
    name: 'accounts',
    initialState,
    error: null,
    reducers:{
        clearError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: {
        [getAccountsData.pending] : (state) => {
            state.loading = true
        },
        [getAccountsData.fulfilled] : (state, action) => {
            state.loading = false
            state.awsAccounts = action.payload
            state.isLoaded = true
        },
        [getAccountsData.rejected] : (state, action) => {
            state.loading = false
            state.error = action.payload
            state.isLoaded = true
            state.error = true
            state.errorMessage = action.payload
        },
    }
})

export const {clearError} = getAccountsSlice.actions

export default getAccountsSlice.reducer