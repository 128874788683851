import React from 'react'
import { Accordion, Badge, Row, Col, Button, Modal, Card, Form } from 'react-bootstrap'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";

import { decisionRequest } from '../features/requestDecisionSlice';

function AdminApprovalCard(props) {

    const [showApprove, setApproveShow] = useState(false);

    const handleApproveClose = () => setApproveShow(false);
    const handleApproveShow = () => setApproveShow(true);

    const [showReject, setRejectShow] = useState(false);

    const handleRejectClose = () => setRejectShow(false);
    const handleRejectShow = () => setRejectShow(true);

    const { instance, inProgress, accounts } = useMsal()
    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState(
        {
            comments: "",
        }
      )

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }


    const { loading } = useSelector((state) => state.admin_decision_status)

    function handleDecision(decision, id, e){
        console.log(decision)
        const form = e.currentTarget;
        e.preventDefault()
        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
        };

        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        }

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                const data = {
                    'access_token': `Bearer ${accessToken}`,
                    'id': id,
                    'requester_email': props.data.requestor_email,
                    'email': instance.getActiveAccount().username,
                    'decision': decision,
                    'comments': formData.comments
                }
                dispatch(decisionRequest(data))
                })
                .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
            });
        }
        if (decision === 'rejected' && form.checkValidity() === true){
            setRejectShow(false)
        }
        if (form.checkValidity() === true){
            setApproveShow(false)
        }
        
    }
  

  return (
    <>
        <Accordion.Item eventKey={props.data.id} className='border-dark'>
            <Accordion.Header>
                <strong>#{props.data.id} Admin Access Request :- </strong> {props.data.account_tag}/{props.data.account_number}
                        <Badge bg="warning" className='mx-auto'>Pending Your Approval</Badge> 
            </Accordion.Header>
            <Accordion.Body>
                <Row>
                    <Col sm={4}>
                    <strong>Request Number:</strong>
                    </Col>
                    <Col>
                        #{props.data.id}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Account Name:</strong>
                    </Col>
                    <Col>
                        {props.data.account_tag}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Requester Email:</strong>
                    </Col>
                    <Col>
                        {props.data.requestor_email}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Account Number:</strong>
                    </Col>
                    <Col>
                        {props.data.account_number}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Request Date/Time (UTC):</strong>
                    </Col>
                    <Col>
                        {props.data.create_time_stamp}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Duration (Day/Days):</strong>
                    </Col>
                    <Col>
                        {props.data.days}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                    <strong>Reason:</strong>
                    </Col>
                    <Col>
                        {props.data.reason}
                    </Col>
                </Row>
                <hr></hr>
                <>
                    <Button variant="primary" onClick={handleApproveShow} value={props.data.id} disabled={loading}>
                            Approve
                    </Button>
                    <Modal show={showApprove} onHide={handleApproveClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>#{props.data.id} Approve Admin Access?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Requester Email:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.requestor_email} </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Account Tag:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.account_tag} </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Account Number:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.account_number} </Card.Text>
                                </Col>
                            </Row>
                            <Form noValidate validated={validated} onSubmit={(e) => handleDecision('approved', props.data.id ,e)}>
                            <Form.Group className="mb-3" controlId="newRequest.ControlInput2">
                                <Form.Label><strong>Approval Comments:</strong></Form.Label>
                                <Form.Control 
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Comments"
                                                    value={formData.comments}
                                                    onChange={handleChange}
                                                    name="comments" 
                                                    required/>
                                <Form.Control.Feedback type="invalid">
                                Please provide approval comments.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" className='mx-3' onClick={handleApproveClose}>
                                Cancel
                            </Button>
                            <Button variant="danger" type="submit" value={props.data.id}>
                                Approved
                            </Button>
                        </Form>
                        </Modal.Body>
                    </Modal>
                </>

                <>
                    <Button variant="danger" onClick={handleRejectShow} value={props.id} className='mx-3' disabled={loading}>
                        Reject
                    </Button>

                    <Modal show={showReject} onHide={handleRejectClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>#{props.data.id} Reject Request?</Modal.Title>
                        </Modal.Header>
                    
                        <Modal.Body>
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Requester Email:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.requestor_email} </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Account Tag:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.account_tag} </Card.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Card.Text><strong>Account Number:</strong> </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text> {props.data.account_number} </Card.Text>
                                </Col>
                            </Row>
                            <Form noValidate validated={validated} onSubmit={(e) => handleDecision('rejected', props.data.id ,e)}>
                                <Form.Group className="mb-3" controlId="newRequest.ControlInput2">
                                    <Form.Label><strong>Rejection Comments:</strong></Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3}
                                        placeholder="Comments"
                                        value={formData.comments}
                                        onChange={handleChange}
                                        name="comments"
                                        required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide rejection comments.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button variant="primary" onClick={handleRejectClose} className='mx-3'>
                                    Cancel
                                </Button>
                                <Button variant="danger" type="submit">
                                    Reject
                                </Button>
                            </Form>
                            
                        </Modal.Body>
                    </Modal>
                </>
                
            </Accordion.Body>
        </Accordion.Item>
    </>
  )
}

export default AdminApprovalCard