import React from 'react'
import { ListGroup, Container, Badge } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'

import { getUserData } from '../features/userSlice'
import { getPendingRequests }  from '../features/getPendingRequestSlice';

function SideBar() {
    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();

    const { loginUser, isLoaded, error } = useSelector((state) => state.user)

    const { requests } = useSelector((state) => state.admin_aproval_pending)

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            console.log(accessToken)
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'user_name': instance.getActiveAccount().name,
            }
            dispatch(getUserData(data))
            dispatch(getPendingRequests(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, accounts, inProgress, dispatch]);



  return (
    <Container>
        <ListGroup className='nav-item'>
            <LinkContainer to="/">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    Home
                </ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/new/request">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    New Request
                </ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/active/requests">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    My Active Requests
                </ListGroup.Item>
            </LinkContainer>
            <LinkContainer to="/expired/requests">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    My Expired Requests
                </ListGroup.Item>
            </LinkContainer>
            { !error && isLoaded && loginUser.role === 'admin' &&
            <LinkContainer to="/approve/request">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    Waiting Approval &#160;
                    {   requests.length > 0 && 
                            <Badge bg="warning">{requests.length}</Badge> 
                    }  &#160; 
                    <span className="badge rounded-pill bg-info">Admin</span> 
                </ListGroup.Item>
            </LinkContainer>
            }
            { !error && isLoaded && loginUser.role === 'admin' &&
            <LinkContainer to="/admin/report">
                <ListGroup.Item action className="list-group-item-info nav-link">
                    Report &#160;
                    <span className="badge rounded-pill bg-info">Admin</span>
                </ListGroup.Item>
            </LinkContainer>
            }
    </ListGroup>
    </Container>
  )
}

export default SideBar
