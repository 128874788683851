import React from 'react'
import ActiveRequestCard from '../components/ActiveRequestCard'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { Container, Spinner, Alert, Accordion } from 'react-bootstrap'

import { getActiveRequests, clearGetActiveRequestError }  from '../features/getActiveRequestsSlice';

import {resetCancelRequest, resetCancelRequestError} from '../features/cancelRequestSlice'

function ActiveRequestPage() {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();
    const { requests, loading, error, errorMessage, isLoaded } = useSelector((state) => state.active_requests)
    const cancel_request = useSelector((state) => state.cancel_request)
    const userRequests = requests.map( request => {
        return <ActiveRequestCard data={request} key={request.account_number}/>
    })

    function handleOnClose(){
       dispatch(resetCancelRequest())
      }
    function handleOnCloseError(){
        dispatch(resetCancelRequestError())
        dispatch((clearGetActiveRequestError()))
    }

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'type': 'active'
            }
            dispatch(getActiveRequests(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch, cancel_request.isCancled]);

  return (
    <Container>
        <p className="lead">Active Requests</p>
        <hr></hr>
        { loading && <Spinner /> }
        { cancel_request.loading && <Spinner /> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert> }
        { cancel_request.error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError} >{cancel_request.errorMessage}</Alert>}
        { cancel_request.isCancled && <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnClose}>
        #{cancel_request.request.request_id} Request Cancelled Successfully!
        </Alert> }
        { isLoaded && requests.length === 0 && <Alert variant='warning' dismissible>
                No Active Request! To Raise a Request click on New Request Tab
            </Alert>}
        <Accordion>
            {userRequests}
        </Accordion>
    </Container>
  )
}

export default ActiveRequestPage