import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    approvers: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getAdminUsers = createAsyncThunk(
     'getAdminUsers',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }
    
    try {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/admin/admins`, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const getAdminUsersSlice = createSlice({
    name: 'getAdminUsers',
    initialState,
    error: null,
    reducers:{
        clearGetAdminUsersError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: {
        [getAdminUsers.pending] : (state) => {
            state.loading = true
        },
        [getAdminUsers.fulfilled] : (state, action) => {
            state.loading = false
            state.approvers = action.payload
            state.isLoaded = true
            state.error = false
        },
        [getAdminUsers.rejected] : (state, action) => {
            state.loading = false
            state.isLoaded = true
            state.error = true
            state.errorMessage =  action.payload
        },
    }
})

export const {clearGetAdminUsersError} = getAdminUsersSlice.actions

export default getAdminUsersSlice.reducer