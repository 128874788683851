import React from 'react'
import { Card, Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'

import { generateReport, clearGenerateReportError, clearGenerateReport } from '../features/generateReportSlice'

function ReportForm() {

    const { requests, isLoaded, error, loading, errorMessage } = useSelector((state) => state.custom_report)

    const { instance, inProgress, accounts } = useMsal();
    const dispatch = useDispatch()

    const [startDate, setStartDate] = useState(dayjs(dayjs(new Date()).subtract(30, 'days').format('YYYY-MM-DD')));
   // const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(dayjs(dayjs(new Date()).format('YYYY-MM-DD')));

    const [validated, setValidated] = useState(false);

    const [requestType, setRequestType] = useState()

    const handleStartChange = (event) => {
        setStartDate(dayjs(event.$d))
    }

    const handleEndChange = (event) => {
        setEndDate(dayjs(event.$d))
    }

    function handleOnCloseReport(){
        dispatch(clearGenerateReportError())
        dispatch(clearGenerateReport())
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("inside if")
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
        };
        if (inProgress === InteractionStatus.None && form.checkValidity()) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    let accessToken = accessTokenResponse.accessToken;
                    const data = {
                        'access_token': `Bearer ${accessToken}`,
                        'email': instance.getActiveAccount().username,
                        'start_date': startDate.format('YYYY-MM-DD'),
                        'end_date' : endDate.format('YYYY-MM-DD'),
                        'report_type': requestType
                    }
                    dispatch(generateReport(data))
                        .catch((error) => {
                            if (error instanceof InteractionRequiredAuthError) {
                                instance.acquireTokenRedirect(accessTokenRequest);
                            }
                        })
                })         
        }
    }

    const handleRadio = (type) =>{
        setRequestType(type)
    }

  return (
    <>
      { loading && <Spinner /> }
      { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseReport}>{errorMessage}</Alert> }
      { isLoaded && <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnCloseReport}>Your report is ready, Click on download button to download it.</Alert> }
    
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Custom Report Form
        </Container>
        <Container className="card-body">
        
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                            label="Report Start Date"
                            onChange={handleStartChange}
                            value={startDate}
                            required
                            disableFuture
                            slotProps={{
                                textField: {
                                  helperText: 'MM/DD/YYYY',
                                },
                              }}
                            />
                        </LocalizationProvider>
                    </Col>
                    <Col>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                        label="Report End Date"
                        onChange={handleEndChange}
                        value={endDate}
                        required
                        disableFuture
                        slotProps={{
                            textField: {
                              helperText: 'MM/DD/YYYY',
                            },
                          }}
                        />
                        </LocalizationProvider>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Type of Request to Include in Report:</Form.Label>
                            <Form.Check 
                                required
                                type="radio"
                                id="custom-switch"
                                label='Only Approved Request'
                                name='report type'
                                value={requestType}
                                feedbackType="invalid"
                                onChange={() => handleRadio('approved')}
                            />
                            <Form.Check 
                                required
                                type="radio"
                                id="custom-switch"
                                label='Approved and Rejected Request'
                                name='report type'
                                feedbackType="invalid"
                                value={requestType}
                                onChange={() => handleRadio("approved_rejected")}
                            />
                            <Form.Check 
                                required
                                type="radio"
                                id="custom-switch"
                                label='Only Rejected Request'
                                name='report type'
                                feedbackType="invalid"
                                value={requestType}
                                onChange={() => handleRadio("rejected")}
                            />
                            <Form.Check 
                                required
                                type="radio"
                                id="custom-switch"
                                label='All Request'
                                name='report type'
                                feedbackType="invalid"
                                feedback="Please Select an Appropriate Report Type!"
                                value={requestType}
                                onChange={() => handleRadio("all")}
                            />
                            <Form.Control.Feedback type="invalid">Please Select an Appropriate report type.</Form.Control.Feedback>
                    </Form.Group>
                    
                </Row>
                <Button type="submit" className='mx-3'>Genereate Report</Button>
                { isLoaded && 
                    <Button type="submit" disabled={!isLoaded} variant='info' href={`data:text/csv;charset=utf-8,${escape(requests)}`} download="custom_report.csv">Download Report</Button>
                }
            </Form>
        
        </Container>
    </Card>
    </>
  )
}

export default ReportForm