import React from 'react'
import AdminApprovalCard from '../components/AdminApprovalCard'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'

import { getPendingRequests, clearGetPendingRequestError }  from '../features/getPendingRequestSlice';
import { Container, Spinner, Alert, Accordion } from 'react-bootstrap';
import { resetDecision, clearDecisionError } from '../features/requestDecisionSlice'

function AdminApprovalPage() {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();
    const { requests, loading, error, errorMessage, isLoaded } = useSelector((state) => state.admin_aproval_pending)
    const adminDecision = useSelector((state) => state.admin_decision_status)
    const adminPendingRequests = requests.map( request => {
        return <AdminApprovalCard data={request} key={request.id}/>
    })

    function handleOnCloseAlert(){
        dispatch(resetDecision())
    }

    function clearError(){
        dispatch(clearGetPendingRequestError())
        dispatch(clearDecisionError())
    }

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getPendingRequests(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch, adminDecision.isProcessed]);

  return (
    <Container>
        <p className="lead">Requests Waiting for Approval</p>
        <hr></hr>
        { adminDecision.loading && <Spinner></Spinner>}
        { loading && <Spinner variant="primary"/>}
        { adminDecision.error && <Alert variant='danger' dismissible className='ms-auto' onClose={clearError}>{adminDecision.errorMessage}</Alert> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={clearError}>{errorMessage}</Alert> }
        { adminDecision.isProcessed &&  
        <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnCloseAlert}>
            Request Proccessed Successfully!
        </Alert> 
        }
        { isLoaded && requests.length === 0 && <Alert variant='warning' dismissible>
                No Pending Request!
            </Alert>}
        <Accordion>
            {adminPendingRequests}
        </Accordion>
    </Container>
  )
}

export default AdminApprovalPage