import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    loading: false,
    isProcessed: false,
    error: false,
    errorMessage: null
}

export const decisionRequest = createAsyncThunk(
     'decisionRequest',
      async (data, {rejectWithValue}) => {
      const headers = {
        'access_token': data.access_token,
        'email': data.email
      }
    const body = {
        'id': data.id, 
        'email': data.requester_email,
        'decision': data.decision, 
        'comments': data.comments
    }
    
    try {

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/decision`, body, { headers })
    
        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const decisionRequestSlice = createSlice({
    name: 'decision',
    initialState,
    error: null,

    reducers:{
        resetDecision: (state) => {
            state.isProcessed = false
        },
        clearDecisionError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },

    extraReducers: {
        [decisionRequest.pending] : (state) => {
            state.loading = true
        },
        [decisionRequest.fulfilled] : (state, action) => {
            state.loading = false
            state.isProcessed = true
            state.error = false
        },
        [decisionRequest.rejected] : (state, action) => {
            state.loading = false
            state.error = true
            state.isProcessed = false
            state.errorMessage = action.payload
        },
    }
})

export const {resetDecision, clearDecisionError} = decisionRequestSlice.actions

export default decisionRequestSlice.reducer