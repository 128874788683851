import { Row, Col, Alert, Container, Card, Button } from 'react-bootstrap'
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate  } from '@azure/msal-react'

import { loginRequest } from '../authConfig'
import SideBar from '../components/Sidebar'


function LoginPage() {

  const { instance } = useMsal()

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  return (
      <Row>
        <UnauthenticatedTemplate>
              <Col>
              <Alert variant='warning' dismissible>
                  Login Using below options to raise Cloud Admin Access!
              </Alert>
              <Card className="card border-dark mb-3">
                  <Container className="card-header">
                     Login Using Azure AD
                  </Container>
                  <Container className="card-body">
                  <Button type="button" variant="primary" onClick={handleLoginRedirect} >Azure AD</Button>
                  </Container>
              </Card>
              </Col>
              <Col sm={3}></Col>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <Col sm={3}>
                  <SideBar />
                </Col>
              </AuthenticatedTemplate>
          </Row>
  )
}

export default LoginPage