import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    credentials: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getCredentials = createAsyncThunk(
     'getCredentials',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'account_number': data.account_number
      }
    
    try {

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/credentials`, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const getCredentialsSlice = createSlice({
    name: 'getCredentials',
    initialState,
    error: null,
    reducers:{
        clearGetCredentialsError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: {
        [getCredentials.pending] : (state) => {
            state.loading = true
        },
        [getCredentials.fulfilled] : (state, action) => {
            state.loading = false
            state.credentials = action.payload
            state.isLoaded = true
            state.error = false
        },
        [getCredentials.rejected] : (state, action) => {
            state.loading = false
            state.isLoaded = true
            state.error = true
            state.errorMessage =  action.payload
        },
    }
})

export const {clearGetCredentialsError} = getCredentialsSlice.actions

export default getCredentialsSlice.reducer