import React from 'react'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'

function ReasonPopOver(props) {
    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Approver's Comments</Popover.Header>
          <Popover.Body>
                {props.data}
          </Popover.Body>
        </Popover>
      );
      const Example = () => (
        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose="true">
          <Button variant="btn btn-outline-success">Reason</Button>
        </OverlayTrigger>
      );
  return (
    
    <Example />
  )
}

export default ReasonPopOver