import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function LogOut() {    

  return (
    <Row>
        {/* <Col sm={3}></Col> */}
        <Col>
            <Container className="alert alert-dismissible alert-success">
                Logout Successfully!
            </Container>
        </Col>
    </Row>
  )
}

export default LogOut