import {configureStore} from "@reduxjs/toolkit"
import userReducer from "./features/userSlice"
import getAccountReducer from "./features/getAccountSlice"
import getActiveRequestsReducer from "./features/getActiveRequestsSlice"
import cancelRequestReducer from "./features/cancelRequestSlice"
import createRequestReducer from "./features/createRequestSlice"
import getPendingRequestsReducer from './features/getPendingRequestSlice'
import decisionRequestReducer from './features/requestDecisionSlice'
import getExpireRequestsReducer from "./features/getExpireRequestsSlice"
import createAccountReducer from './features/addAccountSlice'
import getAdminUsersReducer from './features/getAdminUsersSlice'
import getCredentialsReducer from './features/getCredentialsSlice'
import createUserReducer from './features/addUserSlice'
import getAllUsersReducer from './features/getAllUsersSlice'
import deleteUserReducer from "./features/deleteUserSlice"
import updateUserReducer from "./features/updateUserSlice"
import getReportReducer from "./features/getReportSlice"
import generateReportReducer from './features/generateReportSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        accounts_data: getAccountReducer,
        active_requests: getActiveRequestsReducer,
        cancel_request: cancelRequestReducer,
        create_request: createRequestReducer,
        admin_aproval_pending: getPendingRequestsReducer,
        admin_decision_status: decisionRequestReducer,
        expire_request: getExpireRequestsReducer,
        create_account: createAccountReducer,
        admin_users: getAdminUsersReducer,
        user_cred: getCredentialsReducer,
        add_user: createUserReducer,
        all_users: getAllUsersReducer,
        delete_user: deleteUserReducer,
        update_user: updateUserReducer,
        report : getReportReducer,
        custom_report: generateReportReducer,
    }
})