import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    request: [],
    loading: false,
    isCancled: false,
    error: false,
    errorMessage: null
}

export const cancelRequest = createAsyncThunk(
     'cancelRequest',
      async (data, {rejectWithValue}) => {
      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'id': data.id
      }
    try {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_API_URL}/request`, { headers })
    
        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const cancelRequestSlice = createSlice({
    name: 'request',
    initialState,
    error: null,

    reducers:{
        resetCancelRequest: (state) => {
            state.isCancled = false
        },
        resetCancelRequestError: (state) => {
            state.error = false
            state.errorMessage = null
        },
    },

    extraReducers: {
        [cancelRequest.pending] : (state) => {
            state.loading = true
        },
        [cancelRequest.fulfilled] : (state, action) => {
            state.loading = false
            state.request = action.payload
            state.isCancled = true
            state.error = false
        },
        [cancelRequest.rejected] : (state, action) => {
            state.loading = false
            state.error = action.payload
            state.isCancled = false
            state.error = true
            state.errorMessage = action.payload
        },
    }
})


export const {resetCancelRequest, resetCancelRequestError} = cancelRequestSlice.actions

export default cancelRequestSlice.reducer