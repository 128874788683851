import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    successMessage: null,
    loading: false,
    isSuccess: false,
    error: false,
    errorMessage: null
}

export const deleteUser = createAsyncThunk(
     'deleteUser',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
        'user_email': data.user_email,
        'user_role': data.user_role
      }
    
      console.log(data)
    try {

        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user`, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState,
    error: null,
    reducers:{
        clearDeleteUsersError: (state) => {
            state.error = false
            state.errorMessage = null
        },
        clearDeleteUsers: (state) => {
            state.error = false
            state.errorMessage = null
            state.isSuccess = false
            state.successMessage = null
        }
    },
    extraReducers: {
        [deleteUser.pending] : (state) => {
            state.loading = true
        },
        [deleteUser.fulfilled] : (state, action) => {
            state.loading = false
            state.successMessage = action.payload
            state.isSuccess = true
            state.error = false
        },
        [deleteUser.rejected] : (state, action) => {
            state.loading = false
            state.isSuccess = false
            state.error = true
            state.errorMessage =  action.payload
        },
    }
})

export const {clearDeleteUsersError, clearDeleteUsers} = deleteUserSlice.actions

export default deleteUserSlice.reducer