import React from 'react'
import { useState } from 'react'
import { Form, Row, Col, Button, Card, Container, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import {createAccount} from '../features/addAccountSlice'



function AddAccountForm() {
    const { approvers, isLoaded, error, loading } = useSelector((state) => state.admin_users)
    const [checkedState, setCheckedState] = useState(
        new Array(20).fill(false)
      )

    
    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(
        {
            account_number: "",
            account_tag: "",
        }
      )

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleCheck = (position) =>{
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        const filteredApprovers = approvers.filter((_, index) => checkedState[index]);
        console.log(filteredApprovers)
        if (form.checkValidity() === false) {
            console.log("inside if")
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        const accessTokenRequest = {
            scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
            account: accounts[0],
            };

        if (inProgress === InteractionStatus.None && form.checkValidity()) {
           instance
               .acquireTokenSilent(accessTokenRequest)
               .then((accessTokenResponse) => {
                   let accessToken = accessTokenResponse.accessToken;
                   const data = {
                       'access_token': `Bearer ${accessToken}`,
                       'email': instance.getActiveAccount().username,
                       'account_number': formData.account_number,
                       'account_tag': formData.account_tag,
                       'approver_email_list': filteredApprovers
                   }
                   dispatch(createAccount(data))
                       .catch((error) => {
                           if (error instanceof InteractionRequiredAuthError) {
                               instance.acquireTokenRedirect(accessTokenRequest);
                           }
                       })
               })         
       }
    }

  return (
    <>
    {loading && <Spinner></Spinner> }
    <Card className="card border-dark mb-3">
        <Container className="card-header">
            Add New Account
        </Container>
        
        <Container className="card-body">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Account Tag:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Account Tag"
                            value={formData.account_tag}
                            onChange={handleChange}
                            name="account_tag"
                        />
                        <Form.Control.Feedback type="invalid">Please Provide Account Tag Name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Account Number:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Account Number"
                            value={formData.account_number}
                            onChange={handleChange}
                            name="account_number"
                        />
                        <Form.Control.Feedback type="invalid">Please Provide a Valid 10 digit AWS account number.</Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Account Approvers:</Form.Label>

                        {   !error && isLoaded &&
                            approvers.map( (approver, index) => {
                                return <Form.Check 
                                            key={approver}
                                            type="checkbox"
                                            id="custom-switch"
                                            label={approver}
                                            name={approver}
                                            value={approver}
                                            checked={checkedState[index]}
                                            onChange={() => handleCheck(index)}/>
                            })
                        }
                    </Form.Group>
                </Row>
                <Button type="submit" disabled={loading || error}>Add Account</Button>
            </Form>
        </Container>
    </Card>
    </>
  )
}

export default AddAccountForm