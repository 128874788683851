import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, Spinner, Alert } from 'react-bootstrap'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import { createRequest }  from '../features/createRequestSlice';
import {resetCreateRequest, resetCreateRequestError} from '../features/createRequestSlice'


function NewRequestCard() {

    const dispatch = useDispatch()
    const [validated, setValidated] = useState(false);
    const { instance, inProgress, accounts } = useMsal();
    const { awsAccounts, isLoaded, error } = useSelector((state) => state.accounts_data)
    const create_request = useSelector((state) => state.create_request)
    const [formData, setFormData] = useState(
        {
            awsAccount: "",
            comments: "",
            days: ""
        }
      )

    
    function handleOnClose(){
        dispatch(resetCreateRequest())
    }

    function handleOnCloseError(){
        dispatch(resetCreateRequestError())
    }
    
    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const submitHandler = (e) => {
        const form = e.currentTarget;
        e.preventDefault()
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        }
        
         const accessTokenRequest = {
                 scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
                 account: accounts[0],
                 };
         const awsAccount = awsAccounts.find(awsAccount => {
            return awsAccount.account_number === formData.awsAccount 
         })
         if (inProgress === InteractionStatus.None && form.checkValidity()) {
                instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        let accessToken = accessTokenResponse.accessToken;
                        const data = {
                            'access_token': `Bearer ${accessToken}`,
                            'account_number': formData.awsAccount,
                            'email': instance.getActiveAccount().username,
                            'days': formData.days,
                            'account_tag': awsAccount.account_tag,
                            'reason': formData.comments
                        }
                        dispatch(createRequest(data))
                            .catch((error) => {
                                if (error instanceof InteractionRequiredAuthError) {
                                    instance.acquireTokenRedirect(accessTokenRequest);
                                }
                            })
                    })         
            }
        }

  return (
    <>
    { create_request.loading && <Spinner /> }

    { create_request.isSucess   
        && create_request.create_request_response 
        && <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnClose}>
            #{create_request.create_request_response.request_id} Admin Request Created Successfully!
        </Alert> 
    }

    { create_request.error 
        && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>
        {create_request.errorMessage}
        </Alert> 
    }

    <Card className="card border-dark mb-3">
    <Container className="card-header">
        Raise Cloud Admin Request
    </Container>
    <Container className="card-body">
        <Form noValidate validated={validated} onSubmit={submitHandler}>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="newRequest.ControlInput1">
                        <Form.Label>Select AWS Account:</Form.Label>
                        <Form.Select 
                            aria-label="Default select example"
                            id="awsAccount"
                            name="awsAccount"
                            value={formData.awsAccount}
                            onChange={handleChange}
                            required
                        >
                        <option label='Select an Account ...'></option>
                        {   !error && isLoaded &&
                            awsAccounts.map( awsAccount => {
                                return <option key={awsAccount.account_number} value={awsAccount.account_number}>
                                    {awsAccount.account_tag}/{awsAccount.account_number}
                                </option>
                            })
                        }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Select an Account for which access is needed.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="newRequest.ControlInput2">
                        <Form.Label>Duration in Days</Form.Label>
                        <Form.Select 
                            aria-label="Default select example"
                            id="days"
                            value={formData.days}
                            onChange={handleChange}
                            name="days"
                            required>
                            <option label="Select Duration ..."></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Select Number of Days for which access is needed.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3" controlId="newRequest.ControlTextarea1">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}
                        placeholder="Valid reason for the access"
                        value={formData.comments}
                        onChange={handleChange}
                        name="comments" 
                        required/>
                    <Form.Control.Feedback type="invalid">
                            Please Provide Comments
                    </Form.Control.Feedback>
                </Form.Group> 
            </Row>
            <Button type="submit" disabled={create_request.loading || error}>Raise Request</Button>
        </Form>
    </Container>
    </Card>
    </>
  )
}

export default NewRequestCard