import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {

    auth: {
        clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_AZURE_AUTHORITY}`,
        redirectUri: `${process.env.REACT_APP_AZURE_REDIRECT_URL}`,
        postLogoutRedirectUri: `${process.env.REACT_APP_AZURE_POST_LOGOUT_REDIRECT_URL}`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


export const loginRequest = {
    scopes: ['user.read']
};