import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    loading: false,
    error: false,
    successMessage: null,
    errorMessage: null,
    isSuccess : false
}

export const createUser = createAsyncThunk(
    'createUser',
     async (data, {rejectWithValue}) => {

    const headers = {
    'access_token': data.access_token,
    'email': data.email,
    }
    const body = {
        'user_email': data.user_email, 
        'user_role': data.user_role, 
    }
                               
   try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/user`, body, { headers })
   
       if (response.status === 200) {
           return response.data
       }

       } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
       }
 })

 export const createUserSlice = createSlice({
    name: 'createUser',
    initialState,
    error: null,

    reducers:{
        resetCreateUser: (state) => {
            state.error = false
            state.errorMessage = null
            state.successMessage = null
            state.isSuccess = false
        },
        resetCreateUserError: (state) => {
            state.error = false
            state.errorMessage = null
        },
    },

    extraReducers: {
        [createUser.pending] : (state) => {
            state.loading = true
        },
        [createUser.fulfilled] : (state, action) => {
            state.loading = false
            state.successMessage = action.payload
            state.isSuccess = true
        },
        [createUser.rejected] : (state, action) => {
            state.loading = false
            state.isSuccess = false
            state.error = true
            state.errorMessage = action.payload
        },
    }
})

export const {resetCreateUser, resetCreateUserError} = createUserSlice.actions

export default createUserSlice.reducer