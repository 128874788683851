import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useIsAuthenticated } from '@azure/msal-react';


import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogOut from './pages/LogOut'
import NewRequestPage from './pages/NewRequestPage'
import ActiveRequestPage from './pages/ActiveRequestPage';
import AdminApprovalPage from './pages/AdminApprovalPage';
import ExpiredRequestPage from './pages/ExpiredRequestPage';
import NewUserPage from './pages/NewUserPage';
import AddAwsAccountPage from './pages/AddAwsAccountPage';
import ProfilePage from './pages/ProfilePage';
import EditUserPage from './pages/EditUserPage';
import ReportPage from './pages/ReportPage';
import ErrorPage from './pages/ErrorPage';


function AppRoutes() {
  const isAuthenticated = useIsAuthenticated()
  
  return (
    <main className='my-2'>
      <Container>
        <Routes>
            <Route path='/' element={isAuthenticated ? <HomePage /> : <LoginPage />}/>
            <Route path='/login' element={<LoginPage />}/>
            <Route path='/logout' element={<LogOut/>}/>
            <Route path='/new/request' element={isAuthenticated ? <NewRequestPage/>: <LoginPage />}/>
            <Route path='/active/requests' element={isAuthenticated ? <ActiveRequestPage/> : <LoginPage />}/>
            <Route path='/approve/request' element={isAuthenticated ? <AdminApprovalPage/> : <LoginPage />}/>
            <Route path='/expired/requests' element={isAuthenticated ? <ExpiredRequestPage/> : <LoginPage />}/>
            <Route path='/new/user' element={isAuthenticated ? <NewUserPage/> : <LoginPage />}/>
            <Route path='/new/account' element={isAuthenticated ? <AddAwsAccountPage/>: <LoginPage />}/>
            <Route path='/profile' element={isAuthenticated ? <ProfilePage/>: <LoginPage />}/>
            <Route path='/edit/user' element={isAuthenticated ? <EditUserPage/>: <LoginPage />}/>
            <Route path='/admin/report' element={isAuthenticated ? <ReportPage/>: <LoginPage />}/>
            <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Container>
    </main>
  )
}

export default AppRoutes