import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    requests: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const getPendingRequests = createAsyncThunk(
    'pendingRequest',
     async (data, {rejectWithValue}) => {

     const headers = {
       'access_token': data.access_token,
       'email': data.email,
     }
   
   try {

       const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/admin/requests`, { headers })
   
       if (response.status === 200) {
           return response.data
       }
       } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
       }
 })

 export const getPendingRequestsSlice = createSlice({
    name: 'getPendingRequest',
    initialState,
    error: null,
    reducers:{
        clearGetPendingRequestError: (state) => {
            state.error = false
            state.errorMessage = null
        }
    },
    extraReducers: {
        [getPendingRequests.pending] : (state) => {
            state.loading = true
        },
        [getPendingRequests.fulfilled] : (state, action) => {
            state.loading = false
            state.requests = action.payload
            state.isLoaded = true
        },
        [getPendingRequests.rejected] : (state, action) => {
            state.loading = false
            state.error = action.payload
            state.isLoaded = true
            state.error = true
            state.errorMessage = action.payload
        },
    }
})

export const {clearGetPendingRequestError} = getPendingRequestsSlice.actions

export default getPendingRequestsSlice.reducer