import React from 'react'
import { Container, Accordion, Col, Row, Badge, Alert, Spinner } from 'react-bootstrap'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react'

import { getReport, clearGetReportError } from '../features/getReportSlice'
import ReportForm from '../components/ReportForm';

function ReportPage() {

  const dispatch = useDispatch()
  const { instance, inProgress, accounts } = useMsal();
  const { loading, error, errorMessage, requests } = useSelector((state) => state.report)

  function handleOnCloseError(){
    dispatch(clearGetReportError())
  }

  useEffect(() => {
    const accessTokenRequest = {
    scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
    account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        const data = {
            'access_token': `Bearer ${accessToken}`,
            'email': instance.getActiveAccount().username,
        }
        dispatch(getReport(data))
        })
        .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
        }
        });
    }
}, [instance, inProgress, accounts, dispatch]);

const report_requests = requests.map( request => {return (
  <Accordion.Item eventKey={request.id} className='border-dark' key={request.id}>
    <Accordion.Header >
      <strong>#{request.id} Admin Access Request :- </strong> {request.account_tag}/{request.account_number}
        { request.status === 'pending' &&
                    <Badge bg="warning" className='mx-auto'>Pending Approval</Badge> }
        { request.status === 'rejected' &&
                    <Badge bg="danger" className='mx-auto'>Rejected</Badge> }
        { request.status === 'approved' &&
                    <Badge bg="primary" className='mx-auto'>Active</Badge> }
        { request.status === 'expired' &&
                    <Badge bg="success" className='mx-auto'>Expired</Badge> }  
        { request.status === 'cancelled' &&
                    <Badge bg="info" className='mx-auto'>Cancelled</Badge> }          
    </Accordion.Header>
    <Accordion.Body>
      <Row>
          <Col sm={4}>
          <strong>Request Id:</strong>
          </Col>
          <Col>
              #{request.id}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Account Tag:</strong>
          </Col>
          <Col>
              {request.account_tag}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Account Number:</strong>
          </Col>
          <Col>
              {request.account_number}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Requester Email:</strong>
          </Col>
          <Col>
              {request.requestor_email}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Request Date/Time (UTC):</strong>
          </Col>
          <Col>
              {request.create_time_stamp}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Reason:</strong>
          </Col>
          <Col>
              {request.reason}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Number of Days:</strong>
          </Col>
          <Col>
              {request.days}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Approver Email:</strong>
          </Col>
          <Col>
              {request.decision_maker_email}
          </Col>
      </Row>
      <Row>
          <Col sm={4}>
          <strong>Approver's Comments:</strong>
          </Col>
          <Col>
              {request.comments}
          </Col>
      </Row>
    </Accordion.Body>
  </Accordion.Item>
)})

  return (
    <>
      <Container><p className="lead">Custom Report</p>
      <hr></hr></Container>
      <ReportForm/>
      <Container><p className="lead">Last 10 Requests</p>
      <hr></hr></Container>
      { loading && <Spinner /> }
      { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert> }
        
      <Accordion>
          {report_requests}
      </Accordion>
    </>
  )
}

export default ReportPage