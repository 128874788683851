import React from 'react'
import { Row, Button, Table, Container, Spinner, Col  } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react';


function ProfilePage() {

    const { instance } = useMsal();

    const { loginUser, loading } = useSelector(state => state.user)

    const handleLogoutRedirect = () => {
      instance.logoutRedirect().catch((error) => console.log(error));
    };

  return (
    <Container>
    <Row>
        <p className="lead">User Info</p>
        <hr></hr>
        {loading && <Spinner></Spinner>}
        <Table className="table table-hover">
          <tbody>
            <tr className="table-active">
            <th scope="row">Name</th>
            <td>{loginUser.user_name}</td>
            </tr>
            <tr>
            <th scope="row">Email</th>
            <td>{loginUser.email}</td>
            </tr>
            <tr>
            <th scope="row">Role</th>
            <td>{loginUser.role}</td>
            </tr>
          </tbody>
        </Table>
      
    </Row>
    <Row>
        <Col>
        <Button variant='danger' onClick={handleLogoutRedirect}>Logout</Button>
        </Col>
        
    </Row>
</Container>
  )
}

export default ProfilePage