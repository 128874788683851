import React from 'react'
import { Container, Spinner, Alert, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'

import { getAccountsData } from '../features/getAccountSlice'
import NewRequestCard from '../components/NewRequestCard'
import { clearError } from '../features/getAccountSlice'

function NewRequestPage() {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();
    const { loading, error, errorMessage } = useSelector((state) => state.accounts_data)

    function handleOnCloseError(){
        dispatch(clearError())
    }

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
            }
            dispatch(getAccountsData(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch]);

  return (
    <Container>
        <p className="lead">Raise a Request</p>
        <hr></hr>
        <Row className='text-center'>
            { loading && <Spinner variant="primary"/>}
            { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>
            {errorMessage}
            </Alert> }
        </Row>
       <NewRequestCard />
    </Container>
  )
}

export default NewRequestPage