import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    requests: [],
    loading: false,
    isLoaded: false,
    error: false,
    errorMessage: null
}

export const generateReport = createAsyncThunk(
     'generateReport',
      async (data, {rejectWithValue}) => {

      const headers = {
        'access_token': data.access_token,
        'email': data.email,
      }

      const body = {
        'start_date' : data.start_date,
        'end_date': data.end_date,
        'report_type': data.report_type
      }
    
    try {

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/generate`, body, { headers })

        if (response.status === 200) {
            return response.data
        }
        } catch (error){
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
        }
  })

export const generateReportSlice = createSlice({
    name: 'generateReport',
    initialState,
    error: null,
    reducers:{
        clearGenerateReportError: (state) => {
            state.error = false
            state.errorMessage = null
        },
        clearGenerateReport: (state) => {
            state.error = false
            state.errorMessage = null
            state.isLoaded = false
            state.requests = []
        }
    },
    extraReducers: {
        [generateReport.pending] : (state) => {
            state.loading = true
        },
        [generateReport.fulfilled] : (state, action) => {
            state.loading = false
            state.requests = action.payload
            state.isLoaded = true
            state.error = false
        },
        [generateReport.rejected] : (state, action) => {
            state.loading = false
            state.isLoaded = false
            state.error = true
            state.errorMessage =  action.payload
        },
    }
})

export const {clearGenerateReportError, clearGenerateReport} = generateReportSlice.actions

export default generateReportSlice.reducer