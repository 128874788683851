import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
    loading: false,
    isSuccess: false,
    error: false,
    account: [],
    errorMessage: null
}

export const createAccount = createAsyncThunk(
    'createAccount',
     async (data, {rejectWithValue}) => {

    const headers = {
    'access_token': data.access_token,
    'email': data.email,
    }
    const body = {
        'account_number': data.account_number, 
        'account_tag': data.account_tag, 
        'approver_email_list': data.approver_email_list,
    }
                               
   try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/admin/account`, body, { headers })
   
       if (response.status === 200) {
           return response.data
       }

       } catch (error){
        console.log(error)
            if (!error.response){
                return rejectWithValue(error.message) 
            }
            return rejectWithValue(error.response.data)
       }
 })

 export const createAccountSlice = createSlice({
    name: 'createAccount',
    initialState,
    error: null,

    reducers:{
        resetCreateAccount: (state) => {
            state.isSuccess = false
            state.error = false
            state.errorMessage = null
            state.account = []
        },
        resetCreateAccountError: (state) => {
            state.error = false
            state.errorMessage = null
        },
    },

    extraReducers: {
        [createAccount.pending] : (state) => {
            state.loading = true
        },
        [createAccount.fulfilled] : (state, action) => {
            state.loading = false
            state.account = action.payload
            state.isSuccess = true
        },
        [createAccount.rejected] : (state, action) => {
            state.loading = false
            state.isSuccess = false
            state.error = true
            console.log(action.payload)
            state.errorMessage = action.payload
        },
    }
})

export const {resetCreateAccount, resetCreateAccountError} = createAccountSlice.actions

export default createAccountSlice.reducer