import React from 'react'
import { Container, Table, Spinner, Alert, Badge } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    InteractionRequiredAuthError,
    InteractionStatus,
  } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react'
import ReasonPopOver from '../components/ReasonPopOver';

import { getExpireRequests, clearGetExpireRequestError }  from '../features/getExpireRequestsSlice';

function ExpiredRequestPage() {

    const dispatch = useDispatch()
    const { instance, inProgress, accounts } = useMsal();
    const { requests, loading, error, errorMessage } = useSelector((state) => state.expire_request)

    const expireRequests = requests.map( request => {
        return (<tr key={request.id}>
                    <th>#{request.id}</th>
                    <td>{request.create_time_stamp.slice(0, 10)}</td>
                    <td>{request.account_number}</td>
                    <td>{request.account_tag}</td>
                    <td>
                        {
                            request.status === "rejected" ? 
                                <Badge bg="danger">Rejcted</Badge> :
                                request.status === "expired" ? 
                                <Badge bg="success">Expired</Badge> :
                                <Badge bg="warning">Cancelled</Badge>
                        }
                        </td>
                    <td><ReasonPopOver data={request.comments}/></td>
                </tr>)
    })

    function handleOnCloseError(){
        dispatch(clearGetExpireRequestError())
    }

    useEffect(() => {
        const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
        account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            const data = {
                'access_token': `Bearer ${accessToken}`,
                'email': instance.getActiveAccount().username,
                'type': 'expire'
            }
            dispatch(getExpireRequests(data))
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            });
        }
    }, [instance, inProgress, accounts, dispatch]);

  return (
    <Container>
        <p className="lead"> Last 15 Rejected/Expired/Cancelled Requests</p>
        <hr></hr>
        { loading && <Spinner /> }
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert> }
        
        <Table className='table table-hover'>
            <thead>
                <tr className='table-active'>
                <th>Request ID #</th>
                <th>Date Requested</th>
                <th>AWS #</th>
                <th>Account Tag</th>
                <th>Status</th>
                <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                {expireRequests}
            </tbody>

        </Table>
    </Container>
  )
}

export default ExpiredRequestPage