import { MsalProvider } from '@azure/msal-react';

import Template from "./pages/Template";

function App({ instance }) {
  return (
    <div className="App">
      <MsalProvider instance={instance}>
            <Template />
      </MsalProvider>
      
    </div>
  );
}

export default App;
