import React from 'react'
import { Container, Spinner, Alert } from 'react-bootstrap'
import AddUserForm from '../components/AddUserForm'
import { useSelector, useDispatch } from 'react-redux'
import {resetCreateUser, resetCreateUserError} from '../features/addUserSlice'

function NewUserPage() {

    const dispatch = useDispatch()
    const { successMessage, error, loading, errorMessage, isSuccess } = useSelector((state) => state.add_user)
    function handleOnCloseError(){
        dispatch(resetCreateUserError())
    }

    function handleOnClose(){
        dispatch(resetCreateUser())
    }

  return (
    <Container>
        <p className="lead">Add New User</p>
        <hr></hr>
        { loading && <Spinner variant="primary"/>}
        { error && <Alert variant='danger' dismissible className='ms-auto' onClose={handleOnCloseError}>{errorMessage}</Alert>}
        { isSuccess && <Alert variant='primary' dismissible className='ms-auto' onClose={handleOnClose}>{successMessage}</Alert>}
        <AddUserForm />
    </Container>
  )
}

export default NewUserPage